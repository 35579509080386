<template>
  <div :id="`tab-scraperruns`" class="tab-pane" role="tabpanel">
    <div class="px-3">
      <StartScraperRun :suppliers="filteredSuppliers" :list-accounts="listAccounts" @onStart="onClickStartRun" />
      <div class="row">
        <div class="col-md-8" :class="{ 'col-md-12': !$auth.isAdmin }">
          <h4 class="mb-1">Crawler Runs</h4>
          <p>
            See details about each individual crawler run for accounts under this supplier.
            <span v-if="!$auth.isAdmin"
              >Where a crawler run has failed, you can see the error and also download a screenshot of the page of the portal the crawler failed
              on.</span
            >
          </p>
        </div>
        <div v-if="$auth.isAdmin" class="col-md-4">
          <h4 class="mb-1">Number Of Crawlers In Queue</h4>
          <p>{{ company.settings?.scraperCounter }}</p>
        </div>
      </div>
      <div>
        <div class="bg-light block block-rounded">
          <div class="row">
            <div class="col-md-4">
              <FormGroup
                id="suppliers"
                v-model="filters.selectedSupplier"
                type="select2"
                :config="{ allowClear: true }"
                class="m-3"
                label="Select Supplier"
                placeholder="All Suppliers"
                :options="filteredSuppliers.map(s => ({ label: s.name, value: s._id }))"
                @input="loadScraperRuns"
              />
            </div>
            <div class="col-md-4">
              <FormGroup
                :id="`search-scraperrun`"
                v-model="filters.accountId"
                type="select2"
                :config="{ allowClear: true }"
                label="Select Account"
                placeholder="All Accounts"
                class="m-3"
                :options="accountFilterOptions"
                :disabled="!filters.selectedSupplier"
                @input="loadScraperRuns"
              />
            </div>
            <div class="col-md-3">
              <FormGroup
                :id="`filter-status`"
                v-model="filters.status"
                type="select"
                label="Status"
                class="m-3"
                :options="[
                  { label: 'All', value: '' },
                  { label: 'Completed', value: 'completed' },
                  { label: 'Failed', value: 'error' },
                  { label: 'Login Failure', value: 'login' },
                  { label: 'Data Error', value: 'data' },
                  { label: 'In Progress', value: 'running' }
                ]"
                @input="loadScraperRuns"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :id="`scraperrun-accordion`" class="px-3">
      <div class="row">
        <ul class="nav nav-pills ml-3">
          <li class="d-flex align-items-end mb-2">
            <FormGroup
              id="graph-date-range"
              v-model="filters.dateRange"
              date-format="DD MMM YYYY"
              type="dateRangePicker"
              label="Date Range"
              class="mb-1"
              :style="{
                width: '14rem'
              }"
              :date-range="customDateRanges"
              @input="loadScraperRuns"
            />
            <div>
              <a
                href="javascript:void(0)"
                class="nav-link text-capitalize ml-3"
                :class="{ active: autoRefresh, 'bg-info': autoRefresh }"
                @click="onAutoRefresh()"
              >
                Automatic Refresh
                <div v-if="autoRefresh" class="spinner-border ml-2" style="width: 15px; height: 15px" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <i v-if="!autoRefresh" class="fas fa-sync-alt ml-2"></i>
              </a>
              <div class="text-gray-dark font-w600 font-size-sm mt-1"></div>
            </div>
          </li>
        </ul>
        <Pagination
          v-if="scraperRuns.length > 0"
          :summary="true"
          class="mb-3 ml-auto mr-3 mt-4"
          :current-page="filters.page"
          :total-pages="Math.ceil(scraperRunsTotal / 10)"
          :on-click-page="loadScraperRuns"
          :loading="scraperRunsLoadingAction.list"
        />
      </div>
      <Spinner v-if="scraperRunsLoadingAction.list" />
      <div v-else-if="!scraperRuns.length" class="alert alert-warning mt-3">No crawler runs found with the selected filters.</div>
      <div v-else>
        <!-- scraper run card -->
        <div v-for="scraperRun in scraperRuns" :key="scraperRun._id" class="block block-rounded mb-1">
          <div
            :id="`accordion-${scraperRun._id}-h`"
            class="block-header block-header-default d-block"
            role="tab"
            @click="onClickAccordion(scraperRun)"
          >
            <a class="font-w600" data-toggle="collapse" :data-parent="`scraperrun-accordion`" :href="`#accordion-${scraperRun._id}-q`">
              <div class="d-flex">
                <span class="rounded mr-3 opacity-75" :class="handleStatusClass(scraperRun, 'bg')" style="width: 5px"> </span>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column">
                    <small class="mb-1">
                      {{ scraperRun.createdAt | date('Do MMM YYYY HH:mm:ss') }}
                    </small>
                    {{ handleTitleMessage(scraperRun) }}

                    <div v-if="scraperRun.account">
                      <small class="mr-2"><i class="fa fa-meter fa-fw" /> {{ scraperRun.account.meterPointNumber || scraperRun.deviceId }}</small>
                      <small class="mr-2"
                        ><i class="fa fa-building fa-fw" />
                        <span v-if="scraperRun.account.asset"
                          >{{ scraperRun.account.asset.siteName }} - {{ scraperRun.account.asset.addressString }}</span
                        ><span v-else>NO ASSET</span></small
                      ><small> <i class="fa fa-fingerprint fa-fw" /> {{ scraperRun._id }}</small
                      ><small> <i class="ml-1 fa fa-sm fa-lightbulb"></i> {{ scraperRun.account.supplierRef }}</small>
                    </div>
                    <p class="mb-1">
                      <span v-if="scraperRun.logs?.length > 0" class="text-right mr-2">
                        <span class="badge" :class="handleStatusClass(scraperRun, 'badge')"> {{ handleLabelName(scraperRun) }}</span>
                      </span>
                      <span class="badge badge-primary mr-2"> {{ getSupplierName(scraperRun.supplierId) }} </span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div :id="`accordion-${scraperRun._id}-q`" class="collapse" role="tabpanel" :data-parent="`#scraperrun-accordian`">
            <ScraperLogs :scraper-run="scraperRun" :s3-url="s3Urls[scraperRun._id]" @update-s3-url="(url, id) => $set(s3Urls, id, url)" />
          </div>
        </div>
      </div>

      <Pagination
        v-if="scraperRuns.length > 0"
        :summary="true"
        class="mb-5 mt-3"
        :current-page="filters.page"
        :total-pages="Math.ceil(scraperRunsTotal / 10)"
        :on-click-page="loadScraperRuns"
        :loading="scraperRunsLoadingAction.list"
      />
    </div>

    <ConfirmModal
      :open="!!startRun.modal"
      title="Start Crawler Run"
      :loading="startRun.loading"
      prevent
      @close="startRun.modal = false"
      @submit="onStartRun"
    >
      <div v-if="startRun.supplierId" class="mb-3">
        Please confirm that you would like to start a crawler run for:
        <div class="mb-2 mt-2"><strong>Supplier:</strong> {{ filteredSuppliers.find(s => s._id === startRun.supplierId)?.name }}</div>

        <div v-if="startRun.accountId">
          <div class="mb-2 mt-2"><strong>Account: </strong>{{ scraperRunAccountOptions.find(a => a.value === startRun.accountId).label }}</div>
        </div>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import Pagination from '@/components/Pagination';
import ScraperLogs from '@/components/ScraperLogs';
import StartScraperRun from '@/components/StartScraperRun';
import Spinner from '@/components/Spinner';

import moment from 'moment';

const statusLabels = {
  error: 'Failed',
  completed: 'Completed',
  running: 'In Progress',
  'error.data': 'Data Error',
  'error.login': 'Login Failure'
};

const statusClass = {
  error: 'danger',
  completed: 'success',
  running: 'info',
  'error.data': 'warning',
  'error.login': 'warning'
};

const listenerParams = {
  listeners: [
    { listener: 'insert', mutation: 'scraperRun/INSERT_LOG' },
    { listener: 'update', mutation: 'scraperRun/UPDATE_LOGS' }
  ],
  namespace: 'scraperRun'
};

export default {
  name: 'ScraperRuns',
  components: {
    ConfirmModal,
    FormGroup,
    Pagination,
    ScraperLogs,
    StartScraperRun,
    Spinner
  },
  data() {
    return {
      startRun: {
        supplierId: null,
        accountId: null,
        modal: false,
        loading: false
      },
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last 30 Days': [moment().subtract(30, 'days'), moment()]
      },
      filters: {
        status: '',
        selectedSupplier: '',
        dateRange: [moment(), moment()],
        accountId: '',
        page: 1
      },
      s3Urls: {}
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      scraperRuns: 'scraperRun/scraperRuns',
      scraperRunsTotal: 'scraperRun/scraperRunsTotal',
      scraperRunsLoadingAction: 'scraperRun/loadingAction',
      suppliers: 'scraperRun/suppliers',
      autoRefresh: 'scraperRun/autoRefresh'
    }),
    scraperRunParams() {
      const defaultLimit = 10;
      const formatDate = (date, format = 'YYYY/MM/DD') => moment(date).format(format);

      const params = {
        companyId: this.$auth.companyId,
        $sort: 'createdAt:-1',
        $limit: defaultLimit,
        $skip: (this.filters.page - 1) * defaultLimit,
        status: '$exists',
        $populate: 'account,account.asset',
        $select:
          'logs,accountId,createdAt,account.name,account.meterPointNumber,account.assetId,asset.address,asset.siteName,status,error,supplierId',
        ['createdAt.gte']: formatDate(this.filters.dateRange[0]),
        ['createdAt.lte']: formatDate(moment(this.filters.dateRange[1]).add(1, 'days')),
        supplierId: this.filteredSuppliers.map(s => s.id),
        ...(this.filters.selectedSupplier ? { supplierId: this.filters.selectedSupplier } : {}),
        ...(this.filters.accountId && this.filters.selectedSupplier ? { accountId: this.filters.accountId } : {})
      };

      if (this.filters.status) {
        params.status = this.filters.status;
        if (this.filters.status === 'data' || this.filters.status === 'login') {
          params.error = this.filters.status;
          params.status = 'error';
        } else {
          params.error = 'null';
        }
      }

      return params;
    },
    filteredSuppliers() {
      return this.suppliers
        .filter(s => s.isEnabled)
        .map(s => {
          const scraperRuns = this.scraperRuns.filter(run => run.supplierId === s.id);
          return {
            ...s,
            scraperRuns
          };
        });
    },
    scraperRunAccountOptions() {
      return this.listAccounts(this.startRun.supplierId).map(account => ({
        label: `${account.name} - ${account.meterPointNumber}`,
        value: account._id
      }));
    },
    accountFilterOptions() {
      return this.listAccounts(this.filters.selectedSupplier).map(account => ({
        label: `${account.name} - ${account.meterPointNumber || account.deviceId}`,
        value: account._id
      }));
    }
  },
  async mounted() {
    if (this.$route.query.supplierId) this.filters.selectedSupplier = this.$route.query.supplierId;

    this.loadScraperRuns();
  },
  beforeDestroy() {
    this.emit({ namespace: 'scraperRun', emitTo: 'leaving' });
    this.clearListeners(listenerParams);
  },
  methods: {
    ...mapActions({
      listScraperRuns: 'scraperRun/list',
      getScraperRunLogUrl: 'scraperRun/getLogDownloadUrl',
      startScraperRun: 'scraperRun/startScraperRun',
      emit: 'socket/emit',
      clearListeners: 'socket/clearListeners',
      startListeners: 'socket/startListeners'
    }),
    ...mapMutations({
      setParams: 'scraperRun/SET_PARAMS',
      setAuto: 'scraperRun/SET_AUTO'
    }),
    listAccounts(supplierId) {
      if (!supplierId) return [];

      const foundSupplier = this.filteredSuppliers.find(supplier => supplier.id === supplierId);

      if (!foundSupplier || !foundSupplier.accounts || !foundSupplier.accounts.length) {
        return [];
      }

      const filteredAccounts = [...foundSupplier.accounts];

      if (!filteredAccounts || !filteredAccounts.length) return [];

      filteredAccounts.sort((a, b) => a.name.localeCompare(b.name));

      return filteredAccounts;
    },
    async onStartRun() {
      this.startRun.loading = true;

      const response = await this.startScraperRun({
        companyId: this.$auth.companyId,
        ...(this.startRun.accountId
          ? { accountId: this.startRun.accountId, supplierId: this.startRun.supplierId }
          : { supplierId: this.startRun.supplierId })
      });

      if (response.error) {
        this.$toasted.error(response.error, {
          position: 'bottom-center',
          duration: 6000
        });
      } else {
        this.$toasted.success(response, { position: 'bottom-center', duration: 3000 });
      }

      this.startRun.loading = false;
      this.startRun.modal = false;
    },
    onClickStartRun(supplierId, accountId) {
      this.startRun.supplierId = supplierId;
      this.startRun.accountId = accountId;

      this.startRun.modal = true;
    },
    handleTitleMessage(scraperRun) {
      if (!scraperRun.logs || !scraperRun.logs.length) return null;
      if (scraperRun.status === 'completed') return scraperRun.logs[scraperRun.logs.length - 2].message;
      return scraperRun.logs[scraperRun.logs.length - 1].message;
    },
    async onClickAccordion(scraperRun) {
      if (scraperRun.logs[scraperRun.logs.length - 1]?.s3Url && !this.s3Urls[scraperRun._id]) {
        const previewUrl = await this.getScraperRunLogUrl({
          id: scraperRun._id,
          logId: scraperRun.logs[scraperRun.logs.length - 1]._id
        });

        this.$set(this.s3Urls, scraperRun._id, previewUrl.url);
      }
    },
    getStatusClass(status, error) {
      if (error) {
        const errorIndex = `${status}.${error}`;
        return statusClass[errorIndex];
      }

      return statusClass[status];
    },
    handleStatusClass(scraperRun, scraperRunClass) {
      if (!scraperRun || typeof scraperRun !== 'object') {
        return '';
      }

      const { status, error, createdAt } = scraperRun;

      if (error) {
        const errorClass = this.getStatusClass(status, error);
        return `${scraperRunClass}-${errorClass}`;
      }

      if (this.checkIfInComplete(status, createdAt) && status === 'running') {
        return `${scraperRunClass}-warning`;
      }

      const statusClass = this.getStatusClass(status);
      return `${scraperRunClass}-${statusClass}`;
    },
    handleLabelName(scraperRun) {
      const { status, error, createdAt } = scraperRun;

      if (error) {
        const index = `${status}.${error}`;
        return statusLabels[index];
      }

      if (this.checkIfInComplete(status, createdAt) && status === 'running') return 'In Complete';

      return statusLabels[status];
    },
    onAutoRefresh() {
      if (!this.autoRefresh) {
        this.loadScraperRuns();
        this.emit({ namespace: 'scraperRun', emitTo: 'ready' });
        this.startListeners(listenerParams);
        return this.setAuto();
      }

      this.clearListeners(listenerParams);
      this.setAuto();
    },
    checkIfInComplete(status, date) {
      if (status !== 'running') return false;

      const dateCreated = moment(date);
      const today = moment();

      const difference = today.diff(dateCreated, 'minutes');

      return difference > 10;
    },
    getSupplierName(id) {
      return this.suppliers.find(s => s._id === id)?.name;
    },
    async loadScraperRuns(page) {
      if (page && typeof page === 'number') this.filters.page = page;

      this.setParams(this.scraperRunParams);

      this.listScraperRuns({
        data: { params: this.scraperRunParams }
      });
    }
  }
};
</script>
