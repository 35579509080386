<template>
  <div v-if="$auth.isAdmin">
    <div v-if="!show" class="mb-4 text-right">
      <button data-cy="scraper-starter-btn" class="btn btn-primary" @click.prevent="show = !show">Start Crawler Run</button>
    </div>
    <div v-else data-cy="scraper-starter" class="block block-rounded mb-4">
      <div class="ml-3 mb-5">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-3">Start A Crawler Run</h3>
          </div>
          <div class="col-6 text-right">
            <button data-cy="hide-btn" class="btn btn-primary" @click.prevent="show = !show">Hide</button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <FormGroup
              id="suppliers"
              v-model="supplierId"
              type="select2"
              :config="{ allowClear: true }"
              class="m-3"
              label="Select Supplier"
              placeholder="All Suppliers"
              :options="suppliers.map(s => ({ label: s.name, value: s._id }))"
              @input="accountId = null"
            />
          </div>
          <div class="col-lg-3">
            <FormGroup
              id="accounts"
              v-model="accountId"
              type="select2"
              :config="{ allowClear: true }"
              label="Select Account"
              class="m-3"
              placeholder="All Accounts"
              :options="
                listAccounts(supplierId).map(account => ({
                  label: `${account.name} - ${account.meterPointNumber}`,
                  value: account._id
                }))
              "
              :disabled="!supplierId"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="small mb-2">If an account isn't selected, then all accounts for selected supplier will run.</div>
          <button data-cy="hide-btn" class="btn btn-primary" :disabled="!supplierId" @click.prevent="$emit('onStart', supplierId, accountId)">
            Start
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormGroup from './FormGroup';

export default {
  name: 'StartScraperRun',
  components: {
    FormGroup
  },
  props: {
    suppliers: {
      type: Array,
      required: true
    },
    listAccounts: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      show: false,
      supplierId: null,
      accountId: null
    };
  }
};
</script>
