<template>
  <div class="block block-rounded ml-6">
    <div class="block-content tab-content">
      <table v-if="scraperRun.logs?.length > 0" class="table table-borderless w-auto">
        <tbody>
          <tr v-for="log in scraperRun.logs" :key="log._id">
            <td class="p-3 border-0 mr-3" :class="{ 'pb-1': log.status === 'error' }">
              <i class="fa-lg" :class="handleIcon(log.status)" aria-hidden="true"></i>
            </td>
            <td class="border-0 pl-3">
              <small class="font-size-xs">{{ log.date | date('HH:mm:ss') }}</small>
              <h6 :class="handleClass(log.status)">
                {{ log.message }}
              </h6>
            </td>
          </tr>
          <tr v-if="s3Url">
            <td class="m-0 p-0 border-0 pl-5"></td>
            <td class="border-0">
              <img :src="s3Url" class="rounded img-fluid mx-auto d-block bg-primary border" style="width: 95%" />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="block block-rounded bg-light text-center p-3">No logs available.</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ScraperLogs',
  props: {
    scraperRun: {
      type: Object,
      required: true
    },
    s3Url: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    ...mapActions({
      getScraperRunLogUrl: 'scraperRun/getLogDownloadUrl'
    }),
    handleClass(status) {
      if (status === 'error') {
        if (!this.scraperRun.error) return 'text-danger';
        return 'text-warning';
      }

      if (status === 'completed') return 'text-success';
    },
    handleIcon(status) {
      if (status === 'error') {
        const { error } = this.scraperRun;

        if (!error) return 'fa fa-exclamation-triangle text-danger';

        if (error === 'data') return 'fa-light fa-file-exclamation text-warning';
        return 'fa-regular fa-lock text-warning';
      }

      if (status === 'completed') return 'fas fa-check-circle text-success';
      return 'fas fa-info-circle fa-lg text-primary opacity-50';
    }
  }
};
</script>
